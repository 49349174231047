import React, { useState, useEffect, useContext } from 'react';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import format from 'date-fns/format';
import deLocale from 'date-fns/locale/de';
import API from '../../context/axsioSetup';
import { EmployeesContext, EventsContext } from '../../context/EveMitContext';
import {
    FormContainer,
    Header,
    FormHeader1,
    InputEv,
    SelectEmployee,
    MitarbeiterPicker,
    Button,
    DataContainerDatePicker, CloseButton
} from './EditSearch.element';
import useEmployeeSelection from '../../Hook/useEmployeeSelection';

function EditSearch({ hoveredEvents, setShowEventEdit, setFilteredDetails,filteredDetails }) {
    const { employees } = useContext(EmployeesContext);
    const { filteredEvents, setFilteredEvents, fetchEventsAndUpdate } = useContext(EventsContext);
    const { generateEventColor } = useEmployeeSelection(employees);

    const [eventData, setEventData] = useState({
        event_id: '',
        title: '',
        description: '',
        selectedEmployees: [],
        startTimeEvent: new Date(),
        endTimeEvent: new Date(),
        color: ''
    });

    useEffect(() => {
        if (hoveredEvents && hoveredEvents.length > 0) {
            const event = hoveredEvents[0];
            setEventData({
                event_id: event.event_id,
                title: event.title,
                description: event.description,
                color: event.color,
                selectedEmployees: event.employee_names || [],
                startTimeEvent: new Date(event.start_datetime),
                endTimeEvent: new Date(event.end_datetime),
            });
        }
    }, [hoveredEvents]);

    const removeSelectedEmployee = (employeeName) => {
        setEventData(prevData => ({
            ...prevData,
            selectedEmployees: prevData.selectedEmployees.filter(emp => emp.name !== employeeName)
        }));
    };

    const addSelectedEmployees = (e) => {
        const newSelectedEmployees = Array.from(e.target.selectedOptions, (option) => {
            const employee = employees.find(emp => emp.name === option.value);
            if (eventData.selectedEmployees.find(selectedEmp => selectedEmp.name === employee.name)) {
                return null;
            }
            const color = employee ? (employee.farbe.startsWith('#') ? employee.farbe : `#${employee.farbe}`) : '#default-color';
            return {
                name: option.value,
                color: color,
                id: employee.mitarbeiter_id
            };
        }).filter(emp => emp !== null);
        setEventData(prevData => ({
            ...prevData,
            selectedEmployees: [...prevData.selectedEmployees, ...newSelectedEmployees]
        }));
    };

    const handleDateChange = (date, field) => {
        setEventData(prevData => ({ ...prevData, [field]: date }));
    };
    console.log('filteredDetails von EditSearch', filteredDetails);
    

    const handleEditButtonClick = async () => {
        const formattedStartDate = format(eventData.startTimeEvent, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale });
        const formattedEndDate = format(eventData.endTimeEvent, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale });

        const editedEventData = {
            event_id: eventData.event_id,
            start: formattedStartDate,
            end: formattedEndDate,
            title: eventData.title,
            description: eventData.description,
            selectedEmployees: eventData.selectedEmployees,
            color: generateEventColor(eventData.selectedEmployees),
            hoveredEventIDs: hoveredEvents.map(event => event.event_id)
        };

        try {
            const response = await API.post('/editWiederholendeTermine', editedEventData);
            if (response.status === 200) {
                alert('Die Termine wurden erfolgreich geändert');
                const updatedEvents = filteredEvents.map(event => {
                    if (hoveredEvents.map(e => e.event_id).includes(event.event_id)) {
                        return {
                            ...event,
                            start_datetime: new Date(editedEventData.start),
                            end_datetime: new Date(editedEventData.end),
                            title: editedEventData.title,
                            description: editedEventData.description,
                            employee_names: editedEventData.selectedEmployees,
                            color: editedEventData.color
                        };
                    }
                    return event;
                });
                setFilteredEvents(updatedEvents);
                setFilteredDetails(response.data.updatedEvents);
                console.log('filteredDetails von EditSearch after succeing sending ', filteredDetails);
                
            } else {
                console.error('Fehler beim Bearbeiten der Events');
            }
        } catch (error) {
            console.error('Fehler beim Bearbeiten der Events:', error);
        }setShowEventEdit(false);
    };

    const handleDeleteEvent = async () => {
        const eventIDs = hoveredEvents.map(event => event.event_id);
        const isConfirmed = window.confirm('Möchten Sie diese Termine wirklich löschen?');
        if (isConfirmed) {
            try {
                const response = await API.delete('/deleteEventsRepeat', {
                    headers: { 'Content-Type': 'application/json' },
                    data: { eventIDs }
                });
                if (response.status === 200) {
                    const updatedEvents = filteredEvents.filter(event => !eventIDs.includes(event.event_id));
                    setFilteredEvents(updatedEvents);
                  setFilteredDetails([]);
                    alert('Die Events wurden erfolgreich gelöscht');
                    await fetchEventsAndUpdate();
                } else {
                    console.error('Fehler beim Löschen der Events');
                }
            } catch (error) {
                console.error('Fehler beim Löschen der Events:', error);
            }
            setShowEventEdit(false);
        }
    };

    return (
        <FormContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Header>Termin bearbeiten</Header>
                <CloseButton onClick={() => setShowEventEdit(false)}>
                    <FontAwesomeIcon icon={faTimes} />
                </CloseButton>
            </div>
            <FormHeader1>Titel</FormHeader1>
            <InputEv
                type="text"
                value={eventData.title}
                onChange={(e) => setEventData({ ...eventData, title: e.target.value })}
            />
            <FormHeader1>Beschreibung</FormHeader1>
            <InputEv
                type="text"
                value={eventData.description}
                onChange={(e) => setEventData({ ...eventData, description: e.target.value })}
            />
            <FormHeader1>Mitarbeiter</FormHeader1>
            <MitarbeiterPicker>
                <SelectEmployee onChange={addSelectedEmployees} multiple>
                    {employees.map((employee) => (
                        <option key={employee.mitarbeiter_id} value={employee.name}>
                            {employee.name}
                        </option>
                    ))}
                </SelectEmployee>
                <div>
                    {eventData.selectedEmployees.map((employee) => (
                        <Button key={employee.id} onClick={() => removeSelectedEmployee(employee.name)}>
                            {employee.name} X
                        </Button>
                    ))}
                </div>
            </MitarbeiterPicker>
            <FormHeader1>Startzeit</FormHeader1>
            <DataContainerDatePicker>
                <DatePicker
                    selected={eventData.startTimeEvent}
                    onChange={(date) => handleDateChange(date, 'startTimeEvent')}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd.MM.yyyy HH:mm"
                    locale={deLocale}
                />
            </DataContainerDatePicker>
            <FormHeader1>Endzeit</FormHeader1>
            <DataContainerDatePicker>
                <DatePicker
                    selected={eventData.endTimeEvent}
                    onChange={(date) => handleDateChange(date, 'endTimeEvent')}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd.MM.yyyy HH:mm"
                    locale={deLocale}
                />
            </DataContainerDatePicker>
            <Button onClick={handleEditButtonClick}>Änderungen speichern</Button>
            <Button onClick={handleDeleteEvent}>Event löschen</Button>
        </FormContainer>
    );
}

export default EditSearch;
