import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-height:50vh;
  overflow-y: auto; /* Scrollbar */
  margin: 0; /* Remove auto margin to align left */
  padding: 4px;

  border-radius: 10px;
  font-family: 'Segoe UI', sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Modern shadow */
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    padding: 4px;
    max-height: 500px; /* Reduced height for smaller screens */
  }
`;

export const EmployeeList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px; /* Smaller gap for compact look */
  transition: all 0.3s ease-in-out;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 3px; /* Smaller padding for compact look */
    border-radius: 6px;
    background-color: #ffffff; /* White background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
   

    &:hover {
      background-color: #f8f9fa;
      transform: scale(1.02);
    }

    &:focus-within {
      outline: 2px solid #007bff;
      background-color: #f1f1f1;
    }

    span {
      font-size: 0,7; /* Smaller font size */
      font-weight: 600;
  
      transition: color 0.3s ease;
    }
    &:hover {
   
      transform: scale(1.02);
    }

    svg {
      cursor: pointer;
      font-size: 1em; /* Smaller icon size */
      color: #495057;
      transition: color 0.3s ease, transform 0.3s ease;
    }

    &:hover svg {
      color: #943534;
      transform: scale(1.2);
    }
  }

  @media (max-width: 768px) {
    li {
      flex-direction: column;
      align-items: flex-start;
      padding: 2px;
      text-align: left;
      width:60%;

      span {
        font-size: 0.8em;
        margin-bottom: 2px;
      }

      svg {
        align-self: flex-end;
      }
    }
  }

  @media (max-width: 480px) {
    li {
      padding: 3px;
      gap: 1px;

      span {
        font-size: 0.5em; /* Smaller font size */
        margin-bottom: 2px;
      }

      svg {
        font-size: 0.7em; /* Smaller icon size */
      }
    }
  }
`;