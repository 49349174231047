import styled from "styled-components";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';

export const StyledSelect = styled(Select)`
  width: 100%;
  .react-select__control {
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
      border-color: #aaa;
    }
  }

  .react-select__menu {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .react-select__option {
    padding: 10px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .react-select__multi-value {
    border-radius: 4px;
    padding: 2px;
  }

  @media (max-width: 768px) {
    .react-select__control {
      font-size: 14px;
    }

    .react-select__menu {
      font-size: 14px;
    }
  }
`;

export const FormContainer = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 700px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000000000 !important;
  overflow-y: auto;
  max-height: 90vh;
  overflow: visible;
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    width: 80%;
  
    max-height: 85vh;
  }

  @media (max-width: 480px) {
    width: 80%;
    padding: 15px;
    max-height: 80vh;
  }
`;

export const Header = styled.div`
  width: 100%;
  padding: 10px;
  margin: 1px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction:row;
`;

export const FormHeader1 = styled.h3`
  font-size: 2rem;
  width:100%;
  margin: 0;
  color: #333;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.3rem;
  }
`;

export const InputEv = styled.input`
  width: 100%;
  padding: 14px;
  margin-bottom: 16px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  @media (max-width: 480px) {
    padding: 12px;
  }
`;


export const SelectEmployee = styled.select`
  width: 100%;
  padding: 12px;
  height: 100px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-size: 16px; /* Ensure font-size is at least 16px */
  color: #333;

  @media (max-width: 480px) {
    height: 150px;
  }
`;


export const MitarbeiterPicker = styled.div`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  background-color: #f5f5f5;
`;

export const DataContainer = styled.div`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const DataContainerDatePicker = styled.div`
  width: 100%;
  display: ${(props) => (props.showRepeatComponent ? 'none' : 'flex')};
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative; /* Ensure relative positioning */

 
  

  div {
    flex-grow: 1;
    &:first-child {
      margin-right: 10px;
    }
  }
`;

export const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  font-size: 1rem;
  transition: background-color 0.3s;
  display: ${(props) => (props.showRepeatComponent ? 'none' : 'block')};
  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 480px) {
    padding: 10px;
    font-size: 0.9rem;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 14px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  font-size: 16px;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  .react-datepicker {
    font-family: 'Segoe UI', sans-serif;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .react-datepicker__header {
    background-color: #007bff;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
  }

  .react-datepicker__current-month {
    color: white;
  }

  .react-datepicker__day-name, .react-datepicker__day {
    color: #333;
  }

  .react-datepicker__day--selected {
    background-color: #007bff;
    color: white;
  }

  @media (max-width: 480px) {
    .react-datepicker {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
`;

export const CloseButton = styled.div`
  font-size: 24px;
  cursor: pointer;
  color: #666;
  &:hover {
    color: #333;
  }
`;
