import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { EventsContext } from '../../context/EveMitContext';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Container, DatePickerContainer, Title, StylishButton, MainContainer, Sidebar, Content } from "./SearchEvent.element";
import EventDetails from '../../Component/SearchEventComp/EventDetails';
import MitarbeiterList from '../../Component/MitarbeiterList/MitarbeiterList';

function SearchEvent() {
    const { filteredEvents, fetchEventsAndUpdate } = useContext(EventsContext);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [filteredDetails, setFilteredDetails] = useState([]);
    const navigate = useNavigate();

    // Debounce-Funktion für bessere Performance
    const debounce = (func, delay) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), delay);
        };
    };

    const handleSelectChange = useCallback(
        debounce((selected) => {
            setSelectedOptions(selected || []);
            setShowDetails(false);
        }, 300),
        []
    );

    // Events filtern nach Startzeit, Titel und Mitarbeiter
    const filteredEvents1 = useMemo(() => {
        return filteredEvents.filter(event => {
            const eventStartDate = new Date(event.start_datetime);
            const sameTime = selectedOptions.length === 0 || selectedOptions.some(option => {
                const selectedEvent = option.event;
                return (
                    selectedEvent.title.toLowerCase() === event.title.toLowerCase() &&
                    selectedEvent.employee_name.toLowerCase() === event.employee_name.toLowerCase() &&
                    new Date(selectedEvent.start_datetime).getHours() === eventStartDate.getHours() &&
                    new Date(selectedEvent.start_datetime).getMinutes() === eventStartDate.getMinutes()
                );
            });

            return sameTime;
        });
    }, [filteredEvents, selectedOptions]);

    // Erzeugt die Select-Optionen basierend auf den gefilterten Events
    const eventOptions = useMemo(() => {
        return filteredEvents1.map(event => ({
            value: event.event_id,
            label: `${event.title} - ${new Date(event.start_datetime).toLocaleString()} - ${event.employee_name}`,
            event,
        }));
    }, [filteredEvents1]);

    // Anzeige von Details der gefilterten Events
    const handleShowDetails = useCallback(() => {
        if (selectedOptions.length > 0) {
            const selectedEvent = selectedOptions[0]?.event;
            const filtered = filteredEvents1.filter(event =>
                event.title.toLowerCase() === selectedEvent.title.toLowerCase() &&
              
                event.employee_name.toLowerCase() === selectedEvent.employee_name.toLowerCase()
            );
            setFilteredDetails(filtered);
        }
        setShowDetails(true);
    }, [selectedOptions, filteredEvents1]);

    // Navigiert zurück zur vorherigen Seite
    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        fetchEventsAndUpdate(); // Daten abrufen, wenn die Komponente geladen wird
    }, []);

    return (
        <MainContainer>
            <Sidebar>
                <MitarbeiterList />
            </Sidebar>
            <Content>
                <Container>
                    <StylishButton onClick={goBack}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Zurück
                    </StylishButton>
                    <DatePickerContainer>
                        <DatePicker
                            className="date-picker"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            placeholderText="Startdatum auswählen"
                            dateFormat="dd/MM/yyyy"
                        />
                        <DatePicker
                            className="date-picker"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            placeholderText="Enddatum auswählen"
                            dateFormat="dd/MM/yyyy"
                        />
                    </DatePickerContainer>
                    <Select
                        options={eventOptions}
                        onChange={handleSelectChange}
                        isMulti
                        isLoading={isLoading}
                        placeholder="Suche nach Events"
                    />
                    {selectedOptions.length > 0 && (
                        <>
                            <StylishButton onClick={handleShowDetails}>Details anzeigen</StylishButton>
                            {showDetails && filteredDetails.length > 0 && (
                                <>
                                    <Title>Ausgewählte Event Details:</Title>
                                    <EventDetails filteredDetails={filteredDetails} setFilteredDetails={setFilteredDetails} />
                                </>
                            )}
                        </>
                    )}
                </Container>
            </Content>
        </MainContainer>
    );
}

export default SearchEvent;
