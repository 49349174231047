import { useState, useRef, useEffect, useContext } from 'react';
import format from 'date-fns/format';
import deLocale from 'date-fns/locale/de';
import API from '../context/axsioSetup';
import { EmployeesContext, EventsContext } from '../context/EveMitContext';
import { SettingsContext } from '../context/SettingsContext';
import styled from 'styled-components';
const useCalendarState = () => {
  const calendarRef = useRef(null);
  const [showEventForm, setShowEventForm] = useState(false);
  const [showEventEdit, setShowEventEdit] = useState(false);
  const [hoveredEvent, setHoveredEvent] = useState(null);
  const { settings } = useContext(SettingsContext);
  const [newEvent, setNewEvent] = useState({
    title: '',
    start: '',
    end: '',
    startTimeEvent: '',
    endTimeEvent: '',
    users: [],
    color: '',
    description: '',
  });
  const [view, setView] = useState(() => {
    const savedView = localStorage.getItem('calendarView');
    return savedView || 'month'; // Fallback auf 'month'
  });
  


  const { employees } = useContext(EmployeesContext);
  const {  filteredEvents,setFilteredEvents,fetchEventsAndUpdate } = useContext(EventsContext);


/////////////////////////////////////////////////////////////////
  const moveEventContext = async (event, newDate) => {
    try {
      console.log('Event verschieben:', event);
      console.log('Neues Datum:', newDate);
  
      if (!event || !newDate) {
        console.error('Ungültige Eingabe für das Verschieben des Events.');
        return;
      }
  
      // Start- und Endzeit des Events ermitteln
      const originalStartTime = new Date(event.start_datetime).getHours();
      const originalStartMinutes = new Date(event.start_datetime).getMinutes();
      const originalEndTime = new Date(event.end_datetime).getHours();
      const originalEndMinutes = new Date(event.end_datetime).getMinutes();
  
      // Neues Startdatum mit der ursprünglichen Startzeit
      const newStart = new Date(newDate);
      newStart.setHours(originalStartTime, originalStartMinutes, 0, 0);
  
      // Neues Enddatum mit der ursprünglichen Endzeit
      const newEnd = new Date(newDate);
      newEnd.setHours(originalEndTime, originalEndMinutes, 0, 0);
  
      // Formatierung der neuen Daten für die API
      const formattedStartDate = format(newStart, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale });
      const formattedEndDate = format(newEnd, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale });
  
      // Daten für die API vorbereiten
      const eventData = {
        event_id: event.event_id,
        start: formattedStartDate,
        end: formattedEndDate,
      };
  
      // API-Aufruf, um die Event-Daten zu aktualisieren
      const response = await API.post('/saveeventchanges', eventData, { withCredentials: true });
  
      if (response.status === 200) {
        console.log('Event erfolgreich verschoben:', response.data);
        fetchEventsAndUpdate(); // Event-Liste aktualisieren
      } else {
        console.error('Fehler beim Verschieben des Events:', response.data);
      }
    } catch (error) {
      console.error('Fehler beim Verschieben des Events:', error);
    }
  };
  
  ////////////////////////////////////////////////////////////
  
  const deleteEventContext =async (event) => {
    console.log('DeleteConmtext wurde aufgerufen');
    
    console.log('Event löschen:', event);
    const eventID = event.event_id  ;
    const isConfirmed = window.confirm('Möchten Sie diesen Termin wirklich löschen?');
    if (isConfirmed) {
      try {
        const response = await API.delete(`/deleteEvent`, {
          data: { eventID: eventID },
          withCredentials: true
        });
  
        if (response.status === 200) {
      
          fetchEventsAndUpdate();  // Event-Liste aktualisieren
        
        } else {
  
        }
      } catch (error) {
       console.log('error',error);
       
      }
  
      setShowEventEdit(false); // Schließt das Bearbeitungsfenster nach dem Löschen
    }
  };
  //////////////////////////////////////////////////////////
  //muss verbessert werden 
  const copyEventContext = async (event, newDate) => {
    try {
      console.log('Event kopieren:', event);
      console.log('Neues Datum:', newDate);
  
      if (!event || !newDate) {
        console.error('Ungültige Eingabe für das Kopieren des Events.');
        return;
      }
  
      // Original-Start- und Endzeit extrahieren
      const originalStartTime = new Date(event.start_datetime).getHours();
      const originalStartMinutes = new Date(event.start_datetime).getMinutes();
      const originalEndTime = new Date(event.end_datetime).getHours();
      const originalEndMinutes = new Date(event.end_datetime).getMinutes();
  
      // Neues Startdatum mit der ursprünglichen Startzeit
      const newStart = new Date(newDate);
      newStart.setHours(originalStartTime, originalStartMinutes, 0, 0);
  
      // Neues Enddatum mit der ursprünglichen Endzeit
      const newEnd = new Date(newDate);
      newEnd.setHours(originalEndTime, originalEndMinutes, 0, 0);
  
      // Formatierte Start- und Enddaten
      const formattedStartDate = format(newStart, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale });
      const formattedEndDate = format(newEnd, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale });
  
      // Neues Event-Objekt erstellen
      const newEventObj = {
        title: event.title,
        start: formattedStartDate,
        end: formattedEndDate,

        users: event.employee_names.map(emp=>emp.id) || [], // Falls Benutzerinformationen vorhanden sind
        color: event.color,
        description: event.description || '',
      };
  
      // API-Aufruf zum Hinzufügen des kopierten Events
      const response = await API.post('/addEvent', newEventObj);
  
      if (response.status === 200 || response.status === 201) {
        console.log('Event erfolgreich kopiert:', response.data);
        window.alert('Event wurde erfolgreich kopiert.');
  
        fetchEventsAndUpdate(); // Event-Liste aktualisieren
      } else {
        console.error('Fehler beim Kopieren des Events:', response.data);
        window.alert('Fehler beim Kopieren des Events.');
      }
    } catch (error) {
      console.error('Fehler beim Kopieren des Events:', error);
      window.alert('Ein Fehler ist aufgetreten: ' + error.message);
    }
  };
  //////////////////////////////////////////////////
  
  const handleSelectSlot = (slotInfo) => {
    const { start, end } = slotInfo;
    setNewEvent((prevState) => ({
      ...prevState,
      start: start,
      end: end,
      startTimeEvent: new Date(start),
      endTimeEvent: new Date(end),
    }));
    setShowEventForm(true);
    if (showEventEdit) {
      setShowEventForm(false);
      setShowEventEdit(false);
    }
  };
  /////////////////////////////

  const handleEventHover = (event, e) => {
   
  // console.log('hoverdEventt angerufen ');
   //console.log('hoverdEventt angerufen ',event);
   //console.log('hoverdEventZil',e);
  
     // Check if the target is specifically a <div> with the class 'event-title'
     if (e.target.tagName === 'DIV' && e.target.classList.contains('event-title')) {
       console.log("The target is a <div> with class 'event-title'.");
       setShowEventEdit(true);
       setHoveredEvent(event);
      
     } else {
       console.log("The target is not a <div> with class 'event-title'.");
       setShowEventEdit(false);
       setHoveredEvent(null);
     }
   };
   /////////////////////////

   const saveEventChanges = async (eventData) => {
    try {
      const response = await API.post('/saveeventchanges', eventData, { withCredentials: true });
    
      if (response.status === 200) {
        console.log('Event erfolgreich verschoben:', response.data);
        fetchEventsAndUpdate(); // Event-Liste aktualisieren
      } else {
        console.error('Fehler beim Verschieben des Events:', response.data);
      }
    } catch (error) {
      console.error('Fehler beim Verschieben des Events:', error);
    }
  };
   //////////////////////////////////////////////
  const updateEvent = async ({ event, start, end }) => {
    try {
      if (event && (start || end)) {
        const updatedEvents = [... filteredEvents];
        const eventIndex = updatedEvents.findIndex((e) => e.event_id === event.event_id);
        if (eventIndex !== -1) {
          if (start) updatedEvents[eventIndex].start_datetime = start;
          if (end) updatedEvents[eventIndex].end_datetime = end;
          setFilteredEvents(updatedEvents);

          const formattedStartDate = start
            ? format(start, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale })
            : event.start_datetime;
          const formattedEndDate = end
            ? format(end, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale })
            : event.end_datetime;

          const eventData = {
            event_id: event.event_id,
            start: formattedStartDate,
            end: formattedEndDate,
          };

          saveEventChanges(eventData);
        }
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Events:', error);
    }
  };

  const moveEvent = ({ event, start, end }) => {
    console.log('moveEvent wurde aufgerufen');
    
    updateEvent({ event, start, end });
  };

  const resizeEvent = ({ event, start, end }) => {
    if (!event || (!start && !end)) {
      setFilteredEvents( filteredEvents);
      return;
    }
    updateEvent({ event, start, end });
  };


  

  const eventPropGetter = (event, start, end, isSelected) => {
    const isMobile = window.innerWidth < 768;  // Mobile-Geräte unter 768px Breite
    const isTablet = window.innerWidth >= 768 && window.innerWidth < 1024; // Tablets
    
    const eventBoxStyle = {
      background: event.color || '#007bff',
      color: settings.eventColor || '#fff',
      margin: '2px 0px',
      fontSize: isMobile ? '7px' : isTablet ? '9px' : '12px',  // Dynamische Schriftgröße
      padding: isMobile ? '4px' : '6px', // Kleinere Padding für mobile Ansicht
      borderRadius: '8px',
      transition: 'all 0.3s ease',
      fontWeight: isMobile ? '400' : '600', // Dünnere Schrift für mobile Geräte
      textAlign: 'center',
      cursor: 'pointer',
    };
  
    return { style: eventBoxStyle };
  };
  

  const tooltipAccessor = (event) => {
    if (event) {
      const employeeNames = event.employee_names || [];
      const tooltipContent = `
        Title: ${event.title}
        Beschreibung: ${event.description}
        Start: ${format(new Date(event.start_datetime), 'PPPP HH:mm')}
        End: ${format(new Date(event.end_datetime), 'PPPP HH:mm')}
        MA: '${employeeNames.map((emp) => emp.name).join(', ')}'
      `;
      return tooltipContent;
    }
    return '';
  };


  return {
    calendarRef,
    showEventForm,
    setShowEventForm,
    showEventEdit,
    setShowEventEdit,
    hoveredEvent,
    newEvent,
    setNewEvent,
    employees,
    handleSelectSlot,
    handleEventHover,
    moveEvent,
    resizeEvent,
    eventPropGetter,
    tooltipAccessor,
    view,
    setView,
    setHoveredEvent,
    moveEventContext,
    deleteEventContext,
    copyEventContext
  
  };
};

export default useCalendarState;
