import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

export const Container = styled.div`
 
  font-family: 'Segoe UI', sans-serif;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;

  @media (max-width: 768px) {
    padding: 10px;
    overflow: visible;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
 
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
    overflow: visible;
  }
`;

export const SidebarWrapper = styled.div`
  width: ${({ isListVisible }) => (isListVisible ? '16%' : '0')};
  background-color: white;
  overflow-y: auto;

  transition: width 0.3s ease;

  @media (max-width: 768px) {
    position: absolute;
    left: 0;
    top: 0;
   
    width: 65%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: ${({ isListVisible }) => (isListVisible ? 'translateX(0)' : 'translateX(-100%)')};
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
  }
`;

export const List = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: white;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(109, 99, 99, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 10px;
  }
`;

export const CalendarStyleWrapper = styled.div`
  flex: 1;
  
  width: ${({ isListVisible }) => (isListVisible ? '70%' : '100%')};
  transition: width 0.3s ease;


  @media (max-width: 768px) {
    width: 100%;
  }
`;
