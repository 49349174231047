import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './picker.css';
import CalendarKomponent from '../../Component/Kalender/CalendarKomponent';
import MitarbeiterList from '../../Component/MitarbeiterList/MitarbeiterList';
import { Container, CalendarStyleWrapper, List, Content, SidebarWrapper } from './HomePage.element';
import Header from '../../Component/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTimes } from '@fortawesome/free-solid-svg-icons';
import de from 'date-fns/locale/de';

function HomePage() {
  const [selectedDate, setSelectedDate] = useState(() => {
    const savedDate = localStorage.getItem('selectedDate');
    return savedDate ? new Date(savedDate) : new Date();
  });
  const [isListVisible, setIsListVisible] = useState(true);

  const handleSelectDate = (date) => {
    setSelectedDate(date);
  };

  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsListVisible(false);
      } else {
        setIsListVisible(true);
      }
    };

    handleResize(); // Set initial state based on current window size
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (selectedDate) {
      localStorage.setItem('selectedDate', selectedDate.toISOString());
    }
  }, [selectedDate]);

  return (
    <Container>
      <Header />
      <div>
        <FontAwesomeIcon
          icon={isListVisible ? faTimes : faUser}
          onClick={toggleListVisibility}
          style={{ cursor: 'pointer', fontSize: '24px', color: '#2980b9' }}
        />
      </div>
      <Content>
      
        <SidebarWrapper isListVisible={isListVisible}>
          {isListVisible && (
            <List>
              <DatePicker
                isClearable
                selected={selectedDate}
                onChange={handleSelectDate}
                inline
                locale={de}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                calendarStartDay={1}
                todayButton="Heute"
              />
              <MitarbeiterList />
            </List>
          )}
        </SidebarWrapper>
        <CalendarStyleWrapper isListVisible={isListVisible}>
          <CalendarKomponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        </CalendarStyleWrapper>
      </Content>
    </Container>
  );
}

export default HomePage;