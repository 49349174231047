import React, { useState, useRef, useEffect } from "react";

import { Tooltip } from "react-tooltip";
import CustomEventTooltip from "./CustomEventTooltip";
import useCalendarState from "../../Hook/useCalendarState";
import { format, isValid, parseISO } from 'date-fns';

const CustomEventWithContext = ({ event }) => {
  const {
    moveEventContext,
    deleteEventContext,
    copyEventContext,
  } = useCalendarState();

  const menuRef = useRef(null);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [pickerAction, setPickerAction] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const [activeContextEventId, setActiveContextEventId] = useState(null);

  // Format the selected date for input
  const formattedDate = (() => {
    if (!selectedDate) return "";
    
    let date;
    if (typeof selectedDate === 'string') {
      date = parseISO(selectedDate);
    } else if (selectedDate instanceof Date) {
      date = selectedDate;
    } else {
      date = new Date(selectedDate);
    }
  
    if (!isValid(date)) {
      console.error('Invalid date:', selectedDate);
      return "";
    }
  
    try {
      return format(date, "yyyy-MM-dd");
    } catch (error) {
      console.error('Error formatting date:', error);
      return "";
    }
  })();
  const handleContextMenu = (e) => {
    e.preventDefault();

    // Setze das aktive Event
    setActiveContextEventId(event.event_id);
    console.log("Aktives Event gesetzt:", event.event_id);

    setContextMenuVisible(true);
  };

  const handleOptionClick = (action) => {
    setContextMenuVisible(false);
    setPickerAction(action);

    if (action === "delete") {
     deleteEventContext(event)
    } else {
      setIsPickerVisible(true);
      setSelectedDate(new Date(event.start_datetime));
    }
  };

  const handlePickerConfirm = () => {
    console.log("Picker-Confirm ausgeführt für Action:", pickerAction);
    console.log("Aktives Event:", activeContextEventId);

    if (pickerAction === "move") {
      moveEventContext(event, selectedDate);
    } else if (pickerAction === "copy") {
      copyEventContext(event, selectedDate);
    } else if (pickerAction === "delete") {
      deleteEventContext(event);
    }

    setIsPickerVisible(false);
    setActiveContextEventId(null); // Zurücksetzen des aktiven Events
    console.log("Picker geschlossen und aktives Event zurückgesetzt.");
  };

  // Schließen des Kontextmenüs beim Klick außerhalb
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setContextMenuVisible(false);
        setActiveContextEventId(null); // Zurücksetzen des aktiven Events
        console.log("Kontextmenü geschlossen. Aktives Event zurückgesetzt.");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        data-tooltip-id={`event-tooltip-${event.event_id}`}
        className="rbc-events-container"
        onContextMenu={handleContextMenu}
      >
        <div className="event-title">
          <span className="event-start">
            {format(new Date(event.start_datetime), "HH:mm")}
          </span>{" "}
          - {event.title}
        </div>

        <Tooltip id={`event-tooltip-${event.event_id}`}>
          <CustomEventTooltip event={event} />
        </Tooltip>

        {contextMenuVisible && activeContextEventId && (
          <div className="context-menu" ref={menuRef}>
            <ul className="context-menu-list">
              <li onClick={() => handleOptionClick("move")}>Verschieben</li>
              <li onClick={() => handleOptionClick("delete")}>Löschen</li>
              <li onClick={() => handleOptionClick("copy")}>Kopieren</li>
            </ul>
          </div>
        )}
      </div>

      {isPickerVisible && (pickerAction === "move" || pickerAction === "copy") && (
        <div className="datepicker-container">
          <h3>
            {pickerAction === "move" ? "Event verschieben" : "Event kopieren"}
          </h3>
          <input
            type="date"
            value={formattedDate}
            onChange={(e) =>
              setSelectedDate(new Date(`${e.target.value}T00:00:00`))
            
            }
       
          />
          <button onClick={handlePickerConfirm}>Bestätigen</button>
          <button onClick={() => setIsPickerVisible(false)}>Abbrechen</button>
        </div>
      )}
    </>
  );
};

export default React.memo(CustomEventWithContext);
