import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import de from 'date-fns/locale/de'; // Import German locale
const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #f8f9fa;
  border-bottom: 1px solid #ddd;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;

  button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.3s;

    &:hover {
      background: #0056b3;
    }

    @media (max-width: 480px) {
      padding: 4px 6px;
      font-size: 12px;
    }
  }
`;

const DatePickerWrapper = styled.div`
  .date-picker-label {
    background: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #333;
  }
`;

const ViewSelector = styled(ButtonGroup)`
  button {
   background: #007bff;;
    &:hover {
      background: #218838;
    }
  }
`;

const Icon = styled.span`
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    display: none;
  }
`;

const CustomToolbar = ({ label, onNavigate, setView, view, messages, togglePlanner, plannerActive,  selectedDate,setSelectedDate }) => {
  return (
    <ToolbarContainer>
      <ButtonGroup>
        <button onClick={() => onNavigate('PREV')}>
          <Icon>⬅</Icon> {messages?.previous || 'Back'}
        </button>
        <button onClick={() => onNavigate('TODAY')}>
          {messages?.today || 'Today'}
        </button>
        <button onClick={() => onNavigate('NEXT')}>
          {messages?.next || 'Next'} <Icon>➡</Icon>
        </button>
      </ButtonGroup>

      <DatePickerWrapper>
      <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MMMM d, yyyy"
          style={{position: 'absolute', left: '50%', transform: 'translateX(-50%)'}}
          locale={de}
          customInput={
            <button type="button" className="date-picker-label">
              {label}
            </button>
          }
          popperClassName="custom-datepicker-popper"
          popperPlacement="bottom-start"
          showPopperArrow={false} // Entfernt den Popper-Pfeil für ein sauberes Design
          portalId="root-portal" // Rendern in ein Portal für bessere Z-Index-Steuerung
       
        />
      </DatePickerWrapper>

      <ViewSelector>
        <button onClick={() => setView('month')}>{messages?.month || 'Month'}</button>
        <button onClick={() => setView('week')}>{messages?.week || 'Week'}</button>
        <button onClick={() => setView('day')}>{messages?.day || 'Day'}</button>
        <button onClick={() => setView('agenda')}>{messages?.agenda || 'Agenda'}</button>
        <button onClick={() => setView('work_week')}>{messages?.work_week || 'Work Week'}</button>
        <button className={plannerActive ? 'rbc-active' : ''} onClick={togglePlanner}>
          {messages?.planner || 'Planner'}
        </button>
      </ViewSelector>
    </ToolbarContainer>
  );
};

export default CustomToolbar;
