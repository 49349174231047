import React, { useState, useContext, useEffect } from 'react';
import { dateFnsLocalizer, Calendar } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { LanguageContext } from '../../../context/LanguageContext';
import { EventsContext } from '../../../context/EveMitContext';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';

import { de } from 'date-fns/locale';
import useCalendarState from '../../../Hook/useCalendarState';

import CustomToolbarSched from './CustumToolbarSched';
import MultiCalendars from './renderMultiCalendars';
import EventForm from '../EventForm';
import EventEdit from '../EventEdit';
import CustomResourceHeader from './CustomResourceHeader';

import { SettingsContext } from '../../../context/SettingsContext';
import CustomEventWithContext from '../CustomEventWithContext';

const locales = { de: deLocale, en: enLocale };
const getFormats = (language) => {
  return {
    timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'p', culture),
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, 'p', culture)} - ${localizer.format(end, 'p', culture)}`,
    agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, 'p', culture)} - ${localizer.format(end, 'p', culture)}`,
    dayFormat: (date, culture, localizer) => localizer.format(date, 'eeee MMMM d', culture),
    dateFormat: (date, culture, localizer) => localizer.format(date, 'dd', culture),
    monthHeaderFormat: (date, culture, localizer) => localizer.format(date, 'MMMM yyyy', culture),
    dayHeaderFormat: (date, culture, localizer) => localizer.format(date, 'eeee MMMM d', culture),
    agendaDateFormat: (date, culture, localizer) => localizer.format(date, 'eeee MMMM d', culture),
    monthFormat: (date, culture, localizer) => localizer.format(date, 'MMMM', culture),
    weekdayFormat: (date, culture, localizer) => localizer.format(date, 'eeeeee', culture),
  };
};

const getLocalizer = (language) => {
  const locale = locales[language];
  return dateFnsLocalizer({
    format: (date, formatStr, options) => format(date, formatStr, { ...options, locale }),
    parse: (dateStr, formatStr, options) => parse(dateStr, formatStr, new Date(), { ...options, locale }),
    startOfWeek: (date, options) => startOfWeek(date, { ...options, locale }),
    getDay: (date, options) => getDay(date, { ...options, locale }),
    locales: { [language]: locale },
  });
};


const CalendarWithDragAndDrop = withDragAndDrop(Calendar);

function SchedulerView({ selectedDate, setSelectedDate, setPlannerActive, setMultiSettings }) {
  const { handleSelectSlot, eventPropGetter,moveEvent,resizeEvent,  showEventForm,handleEventHover,  calendarRef, setShowEventForm,setNewEvent,showEventEdit, setShowEventEdit,hoveredEvent,newEvent} = useCalendarState();
  const { language } = useContext(LanguageContext);
  const localizer = getLocalizer(language);
  const { filteredEvents, resources } = useContext(EventsContext);
  const formats = getFormats(language);
  const formattedDate = format(new Date(selectedDate), 'PPP', { locale: de }); 
const { settings } = useContext(SettingsContext);

  const [view, setView] = useState(() => JSON.parse(localStorage.getItem('scheduleView')) || 'day');
  const [multiCount, setMultiCount] = useState(() => JSON.parse(localStorage.getItem('multiCount')) || 1);


  useEffect(() => {
    const savedDate = localStorage.getItem('selectedDate');
    if (savedDate) setSelectedDate(new Date(savedDate));
  }, [setSelectedDate]);

  useEffect(() => {
    if (selectedDate) localStorage.setItem('selectedDate', selectedDate.toISOString());
  }, [selectedDate]);

  useEffect(() => {
    localStorage.setItem('scheduleView', JSON.stringify(view));
    localStorage.setItem('multiCount', JSON.stringify(multiCount));
  }, [view, multiCount]);




  return (
    <div style={{ padding: '20px', margin: '10px' }}>
     
     <div>
        <span style={{ fontWeight: 'bold' }}>Datum: </span>
        {formattedDate}
      </div>
      {(view !== 'multiDay' && view !== 'multiWeek') && (
        <CalendarWithDragAndDrop
        ref={calendarRef}
          localizer={localizer}
          style={{ height: '80vh'}}
          events={filteredEvents}
          formats={formats}
          step={settings.step}
          timeslots={settings.timeslots}
          selectable
          date={selectedDate}
          onNavigate={setSelectedDate}
          allDay={true}
          startAccessor={(event) => new Date(event.start_datetime)}
          endAccessor={(event) => new Date(event.end_datetime)}
          view={view}
          views={['week', 'day']}
          onSelectSlot={handleSelectSlot}
          resizable
          onEventDrop={moveEvent}
          onEventResize={resizeEvent}
          draggable
          eventPropGetter={eventPropGetter}
          dayLayoutAlgorithm={'no-overlap'}
        
          min={new Date('2023-07-20T07:00:00')}
          max={new Date('2023-07-20T21:00:00')}
          onSelectEvent={handleEventHover}
        
          resources={resources}
          components={{
            resourceHeader: CustomResourceHeader, 
            toolbar: (props) => (
              <CustomToolbarSched
                {...props}
                view={view}
                onView={setView}
                multiCount={multiCount}
                onMultiChange={setMultiCount}
                date={selectedDate}
                setPlannerActive={setPlannerActive}
                setMultiSettings={setMultiSettings}
              />
            ),
            event: ({ event }) => (
                       <CustomEventWithContext event={event} selectedDate={selectedDate}/>
                      )
          }}
        />
      )}

      {(view === 'multiDay' || view === 'multiWeek') && (
        <MultiCalendars
          localizer={localizer}
          filteredEvents={filteredEvents}
          resources={resources}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          view={view}
          onView={setView}
          multiCount={multiCount}
          setMultiCount={setMultiCount}
          eventPropGetter={eventPropGetter}
          setMultiSettings={setMultiSettings}
          setPlannerActive={setPlannerActive}
        />
      )}
      {showEventForm && (
        <EventForm
          setShowEventForm={setShowEventForm}
          newEvent={newEvent}
          setNewEvent={setNewEvent}
        />
      )}
      {showEventEdit && hoveredEvent && (
        <EventEdit
          hoveredEvent={hoveredEvent}
       
          setShowEventEdit={setShowEventEdit}
          setNewEvent={setNewEvent}
        />
      )}
    </div>
  );
}

export default SchedulerView;
