import styled, { keyframes } from 'styled-components';

// Color Animation for background
const colorAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Shape Animation
const shapeAnimation = keyframes`
  0%, 100% { border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%; }
  50% { border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%; }
`;

// Responsive breakpoints
const breakpoints = {
  mobile: '480px',
  tablet: '768px',
  laptop: '1024px',
};

export const Container = styled.div`
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: ${colorAnimation} 15s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 95vh; 
  width: 100%;
  padding: 20px;
  overflow: hidden; /* Prevent extra scrolling */

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    padding: 15px;
    min-height: 98vh; 
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 450px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  padding: 40px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  overflow: hidden;

  @media (max-width: ${breakpoints.tablet}) {
    width: 90%;
    padding: 30px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 10px;
  }
`;

export const Shape = styled.div`
  position: absolute;
  animation: ${shapeAnimation} 8s ease-in-out infinite;
  z-index: 0;
`;

export const ShapeFirst = styled(Shape)`
  background: linear-gradient(45deg, #1845ad, #23a2f6);
  width: 150px;
  height: 150px;
  top: -50px;
  left: -50px;
`;

export const ShapeLast = styled(Shape)`
  background: linear-gradient(45deg, #ff512f, #f09819);
  width: 150px;
  height: 150px;
  bottom: -50px;
  right: -50px;
`;

export const FormHeader = styled.h2`
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Label = styled.label`
  display: block;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
`;

export const InputContainer = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  font-size: 16px;
  color: #ffffff;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background: linear-gradient(45deg, #1845ad, #23a2f6);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #23a2f6, #1845ad);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
`;
