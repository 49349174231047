import React, { useState, useContext } from 'react';
import AuthContext from "../../context/AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  ShapeFirst,
  ShapeLast,
  Form,
  FormHeader,
  Label,
  Input,
  Button,
  InputContainer,
} from './Login.element';

const LoginComponent = () => {
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(username, password);
      setError('');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Container>
      <ShapeFirst />
      <ShapeLast />

      <Form onSubmit={handleSubmit}>
        <FormHeader>Gronde Sehen & Hören Kalender</FormHeader>
        {error && <p style={{ color: '#ff6b6b', textAlign: 'center' }}>{error}</p>}

        <Label htmlFor="username">Username</Label>
        <InputContainer>
          <Input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
        </InputContainer>

        <Label htmlFor="password">Passwort</Label>
        <InputContainer>
          <Input type={showPassword ? 'text' : 'password'} id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)} style={{ position: 'absolute', right: '15px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', color: 'rgba(255, 255, 255, 0.5)' }} />
        </InputContainer>

        <Button type="submit">Login</Button>
      </Form>
    </Container>
  );
};

export default LoginComponent;
