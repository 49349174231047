import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import API from './axsioSetup';
import AuthContext from './AuthContext';

export const EmployeesContext = createContext();
export const EventsContext = createContext();

export const EmployeesProvider = React.memo(({ children }) => {
  const { user } = useContext(AuthContext);
  const userId = user ? user.username : null;
  const [employees, setEmployees] = useState([]);
  const [employeeIds, setEmployeeIds] = useState([]);


  useEffect(() => {
    if (!userId) return;

    const fetchEmployees = async () => {
      try {
        const response = await API.get(`/getMitarbeiterByUserId?userId=${userId}`);
        setEmployees(response.data);
        const ids = response.data.map((emp) => emp.mitarbeiter_id);
        setEmployeeIds(ids);
        //console.log('Fetched employees:', response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Mitarbeiter:', error);
      }
    };

    fetchEmployees();
  }, [userId]);

  const employeesContextValue = useMemo(
    () => ({ employees, employeeIds, setEmployees }),
    [employees, employeeIds]
  );

  return (
    <EmployeesContext.Provider value={employeesContextValue}>
      {children}
    </EmployeesContext.Provider>
  );
});

export const EventsProvider = React.memo(({ children }) => {
  const { employeeIds, employees } = useContext(EmployeesContext);
  const [allEvents, setAllEvents] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
//console.log('firstLog',employees);
const [resources, setResources] = useState([]);


useEffect(() => {
  if (!employees) return;

  // Filter resources based on filteredEmployees
  const filteredResources = employees
    .filter(employee => 
      filteredEmployees.length === 0 || filteredEmployees.includes(employee.mitarbeiter_id)
    )
    .map(employee => ({
      id: employee.mitarbeiter_id,
      title: employee.name,
      color: employee.farbe,
    }));
    
  setResources(filteredResources);
}, [employees, filteredEmployees])
const fetchEventsAndUpdate = async () => {
  if (employeeIds.length === 0) return;

  try {
    const response = await API.get('/showevents', {
      params: { employeeIds: JSON.stringify(employeeIds) },
        timeout: 30000, 
      
    });


    const formattedEvents1 = response.data.map((event) => {
 
      
      const resourceIds = event.employee_names.map((employee) => employee.id);
      return {
        ...event,
        start_datetime: new Date(event.start_datetime),
        end_datetime: new Date(event.end_datetime),
        resourceIds,
        id: event.event_id || null,
      };
    });
    const formattedEvents = formattedEvents1.map(event => ({
      ...event,
      resourceId: Array.isArray(event.resourceIds) ? event.resourceIds : [],
    
      title: event.title || 'Untitled Event',
    }));


    setAllEvents(formattedEvents);
  } catch (error) {
    console.error('Fehler beim Abrufen der Events:', error);
  }
};
  useEffect(() => {
    fetchEventsAndUpdate();
    const intervalId = setInterval(fetchEventsAndUpdate, 15 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [employeeIds]);
  

 // Adjust filtering logic in EventsProvider to fix reverse filtering
useEffect(() => {

  
  if (!employees) return;

  const newFilteredEvents = filteredEmployees.length
    ? allEvents.filter((event) => {
        const eventEmployeeIds = event.employee_names.map((emp) => emp.id);
        // Only include events if they match exactly with selected filteredEmployees
        return eventEmployeeIds.some((id) => filteredEmployees.includes(id));
      })
    : allEvents; // If no filteredEmployees, show all events

  setFilteredEvents(newFilteredEvents);
  //console.log('Filtering applied:', { filteredEmployees, newFilteredEvents });
}, [allEvents, filteredEmployees, employees]);


  const eventsContextValue = useMemo(
    () => ({
      allEvents,
      
      filteredEvents,
      setFilteredEmployees,
      setFilteredEvents,
      fetchEventsAndUpdate,
      resources,
    }),
    [allEvents, filteredEvents]
  );

  return (
    <EventsContext.Provider value={eventsContextValue}>
      {children}
    </EventsContext.Provider>
  );
});

export const AppContextProvider = ({ children }) => (
  <EmployeesProvider>
    <EventsProvider>{children}</EventsProvider>
  </EmployeesProvider>
);
/** */