import React, { memo, useCallback, useContext } from 'react';
import { Calendar } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import CustomToolbarSched from './CustumToolbarSched';
import useCalendarState from '../../../Hook/useCalendarState';
import EventForm from '../EventForm';
import EventEdit from '../EventEdit';
import CustomResourceHeader from './CustomResourceHeader';
import { SettingsContext } from '../../../context/SettingsContext';
import CustomEventWithContext from '../CustomEventWithContext';
import './renderMultiCalendars.css';

const CalendarWithDragAndDrop = withDragAndDrop(Calendar);

const CustomToolbar = ({ label }) => (
  <div style={{ textAlign: 'center', padding: '10px 0' }}>
    <span>{label}</span>
  </div>
);

const MultiCalendars = memo(({
  localizer,
  filteredEvents,
  resources,
  selectedDate,
  setSelectedDate,
  view,
  onView,
  multiCount,
  setMultiCount,
  setPlannerActive,
  setMultiSettings,
}) => {
  const {
    handleSelectSlot, eventPropGetter, moveEvent, resizeEvent,
    showEventForm, handleEventHover, calendarRef,
    setShowEventForm, setNewEvent, showEventEdit,
    setShowEventEdit, hoveredEvent, newEvent
  } = useCalendarState();
  
  const { settings } = useContext(SettingsContext);

  const renderCalendar = useCallback((index) => {
    const dateOffset = index * (view === 'multiDay' ? 1 : 7);
    const calendarDate = new Date(selectedDate);
    calendarDate.setDate(selectedDate.getDate() + dateOffset);

    return (
      <div key={index}>
        {index === 0 && (
          <CustomToolbarSched
            view={view}
            onView={onView}
            multiCount={multiCount}
            onMultiChange={setMultiCount}
            date={selectedDate}
            setPlannerActive={setPlannerActive}
            setMultiSettings={setMultiSettings}
          />
        )}
        <CalendarWithDragAndDrop
        className='multi-calendar-container'
          localizer={localizer}
          style={{ height: '77vh', margin: '10px 10px' }}
          events={filteredEvents}
          date={calendarDate}
          view={view === 'multiDay' ? 'day' : 'week'}
          step={settings.step}
          timeslots={settings.timeslots}
          allDay
          startAccessor={(event) => new Date(event.start_datetime)}
          endAccessor={(event) => new Date(event.end_datetime)}
          resources={resources}
          min={new Date('2023-07-20T07:00:00')}
          max={new Date('2023-07-20T21:00:00')}
          eventPropGetter={eventPropGetter}
          onSelectSlot={handleSelectSlot}
          selectable
          draggable
          dayLayoutAlgorithm={'no-overlap'}
          popup
          onEventDrop={moveEvent}
          onSelectEvent={handleEventHover}
          onEventResize={resizeEvent}
          components={{
            resourceHeader: CustomResourceHeader,
            toolbar: CustomToolbar,
            event: ({ event }) => (
              <CustomEventWithContext event={event} selectedDate={selectedDate} />
            ),
          }}
        />
        {showEventForm && (
          <EventForm
            setShowEventForm={setShowEventForm}
            newEvent={newEvent}
            setNewEvent={setNewEvent}
          />
        )}
        {showEventEdit && hoveredEvent && (
          <EventEdit
            hoveredEvent={hoveredEvent}
            setShowEventEdit={setShowEventEdit}
            setNewEvent={setNewEvent}
          />
        )}
      </div>
    );
  }, [
    localizer, filteredEvents, selectedDate, view, eventPropGetter,
    resources, onView, multiCount, setMultiCount, handleSelectSlot,
    moveEvent, resizeEvent, handleEventHover, setPlannerActive,
    setMultiSettings
  ]);

  return <div>{Array.from({ length: multiCount }).map((_, index) => renderCalendar(index))}</div>;
});

export default MultiCalendars;
