import React, { useState } from 'react';
import {
    EventTitle,
    EmployeeBadge,
    EmployeeContainer,
    EventContainer,
    Divider,
    StylishButton,
    CheckboxLabel,
    CheckboxInput
} from './EventDetail.element';
import EditSearch from './EditSearch';

function EventDetails({ filteredDetails, setFilteredDetails }) {
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [showEdit, setShowEdit] = useState(false);

    const handleSelectAllChange = (e) => {
        setSelectedEvents(e.target.checked ? filteredDetails : []);
    };

    const handleCheckboxChange = (event) => {
        setSelectedEvents(prevSelected => 
            prevSelected.includes(event)
                ? prevSelected.filter(e => e !== event)
                : [...prevSelected, event]
        );
    };

    const handleEditButtonClick = () => {
        setShowEdit(true);
    };

    const handleEditClose = () => {
        setShowEdit(false);
    };
console.log('filteredDetails von EventDetail', filteredDetails);

    return (
        <>
            <CheckboxLabel>
                <CheckboxInput
                    type="checkbox"
                    checked={selectedEvents.length === filteredDetails.length}
                    onChange={handleSelectAllChange}
                />
                Select Alles
            </CheckboxLabel>
            <StylishButton onClick={handleEditButtonClick}>Bearbeiten</StylishButton>
            {filteredDetails.map(event => (
                <EventContainer key={event.event_id}>
                    <CheckboxInput
                        type="checkbox"
                        checked={selectedEvents.includes(event)}
                        onChange={() => handleCheckboxChange(event)}
                    />
                    <EventTitle>{event.title}</EventTitle>
                    <EmployeeContainer>
                        {event.employee_names.map((employee, index) => (
                            <EmployeeBadge key={index} style={{ backgroundColor: employee.color }}>
                                {employee.name}
                            </EmployeeBadge>
                        ))}
                    </EmployeeContainer>
                    <Divider />
                </EventContainer>
            ))}
            {showEdit && (
                <EditSearch
                    hoveredEvents={selectedEvents}
                    setShowEventEdit={handleEditClose}
                    setFilteredDetails={setFilteredDetails}
                    filteredDetails={filteredDetails}
                />
            )}
        </>
    );
}

export default EventDetails;
